import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Modal } from 'react-bootstrap';
import { Button } from "../../../components/layout/BotSchedulingUI";
import { Grid2 as Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import FormCreator from "../../../components/formcreator/FormCreator";
import iconList from '../../../data/IconList.json';

import api from "../../../network/Api";

import AttributesContext from "../../../context/attributes/attributesContext";
import ResourcesContext from "../../../context/resources/resourcesContext";

const CreateResourceModal = ({displayModal, handleCloseModal, ...props}) => {
	const [loading, setLoading] = useState(props.loading ?? false);
	const initialFormControls = [
		{
			label: "Icon",
			type: "searchablelist",
			name: "icon",
			value: "",
			size: "lg",
			options: iconList,
			validationRules: {
				required: {
					value: true,
					message: "Icon is required"
				}
			},
			col: 2
		},
		{
			label: "Singular Name",
			type: "input",
			name: "singularName",
			value: "",
			size: "lg",
			placeholder: "e.g., Truck, Chair, etc.",
			validationRules: {
				required: {
					value: true,
					message: "Singular name is required"
				},
			},
			col: 5
		},
		{
			label: "Plural Name",
			type: "input",
			name: "pluralName",
			value: "",
			size: "lg",
			placeholder: "e.g., Trucks, Chairs, etc.",
			validationRules: {
				required: {
					value: true,
					message: "Plural name is required"
				},
			},
			col: 5
		}
	];
	const [formControls, setFormControls] = useState(initialFormControls);

	let navigate = useNavigate();

	const attributesContext = useContext(AttributesContext);
	const resourcesContext = useContext(ResourcesContext);

	useEffect(() => {
		if (attributesContext.attributes === null) {
			attributesContext.loadAttributes();
		}
	}, []);

	useEffect(() => {
		if (attributesContext.attributes !== null) {
			setFormControls([...initialFormControls, {
				label: "Choose the attributes to associate with this resource.",
				// tooltip: "Learn more about attributes.<br />https://botscheduling.com/docs/resource-attributes",
				type: "cardcheckbox",
				name: "attributes",
				value: [],
				size: "lg",
				placeholder: "e.g., Trucks, Chairs, etc.",
				validationRules: {
					required: {
						value: true,
						message: "Name is required"
					},
					minLength: {
						value: 4, message: "Name is too short" },
				},
				options: attributesContext.attributes.map(attribute => {
					return {
						label: attribute.name,
						name: attribute.name,
						resourceCode: attribute.resourceCode,
						icon: attribute.icon,
						description: attribute.description,
						validationRules: {},
						col: 4
					}
				}),
				col: 12
			}]);
		}
	}, [attributesContext.attributes]);

	const createResource = (data) => {
		const defaultField = {
			"label": "Name",
			"type": "text",
			"description": "The name of the record.",
			"default": null,
			"isRequired": false,
			"isDisplayLabel": true
		};

		const apiData = data;
		apiData.fields = [defaultField];

		setLoading(true)

		api.post(`/core/v1/resource-management`, apiData, {
			validateStatus: function (status) {
				return status === 201 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				props.alertContext.setAlert('Something went wrong', 'Unable to create a resource. Please try again later or email support@botscheduling.com.', 'danger');
			} else {
				props.alertContext.setAlert('Success', 'Resource has been created.', 'success');
				resourcesContext.getResources();
				closeModal();
				navigate(`/resource-management/${response.data.resourceCode}`);
			}
			setLoading(false);
		}).catch(function (error) {
			console.log(error);
			props.alertContext.setAlert('Whoops', error.response?.data?.message ?? 'An unknown error has occurred.', 'danger');
			setLoading(false);
		});
	}

	const [ form ] = useState(useForm({
		mode: 'all',
	}));

	const {
		handleSubmit,
		reset
	} = form;

	const closeModal = () => {
		setLoading(false);
		reset();
		handleCloseModal();
	}

	return (
		<Modal show={displayModal} size="xl" onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title as="h4">
					Create Resource
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormCreator form={form} formControls={formControls} onSubmit={createResource} />
				<h4 className="mt-4 mb-2">Learn more about Resources</h4>
				<Grid container spacing={2}>
					<Grid size={6}>
						<Card>
							<Card.Body className="card-hover">
								<a href="https://botscheduling.com/docs/resource-management#what-are-resources" target="_blank">
									<div className="text-light text-uppercase"><strong>Product Docs</strong></div>
									<div className="text-primary">What are Resources?</div>
								</a>
							</Card.Body>
						</Card>
					</Grid>
					<Grid size={6}>
						<Card>
							<Card.Body className="card-hover">
								<a href="https://botscheduling.com/docs/utilizing-resources" target="_blank">
									<div className="text-light text-uppercase"><strong>Product Docs</strong></div>
									<div className="text-primary">Use resources when booking Appointments.</div>
								</a>
							</Card.Body>
						</Card>
					</Grid>
				</Grid>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-between">
				<div>

				</div>
				<div>
					<Button
						variant="link"
						onClick={closeModal}
						disabled={loading}
						keyDown={(event) => {
							{event.ctrlKey && event.key === 'c' &&
								closeModal();
							}
						}}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => handleSubmit(createResource)()}
						loading={loading}
					>
						{loading ? "Creating..." : "Create Resource"}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default CreateResourceModal