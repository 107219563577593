import React, { useContext, useEffect } from 'react'
import { Route, Navigate } from 'react-router-dom'

import AuthContext from '../context/auth/authContext'

const LoggedInRoute = ({ children, ...rest }) => {
	const authContext = useContext(AuthContext);

	const { loading, loadAuthentication, authenticationToken } = authContext;

	useEffect(() => {
		loadAuthentication(localStorage.getItem('token'), localStorage.getItem('workspaceIdentifier'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <div />;
	}

	if (authenticationToken === null) {
		return <Navigate to='/auth/login'/>
	}

	return React.cloneElement(children, { ...rest });
}

export default LoggedInRoute