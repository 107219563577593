import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import CheckAccess from "../utils/CheckAccess";

import Loading from "../components/Loading";

import AuthContext from '../context/auth/authContext';
import ApplicationContext from '../context/application/applicationContext';
import ResourcesContext from '../context/resources/resourcesContext';
import AlertContext from "../context/alert/alertContext";

const LoggedInAndWorkspaceSelectedRoute = ({ children, ...rest }) => {
	const authContext = useContext(AuthContext);
	const applicationContext = useContext(ApplicationContext);
	const resourcesContext = useContext(ResourcesContext);
	const alertContext = useContext(AlertContext);

	const { currentWorkspace, authenticationToken, workspaces, userAccess, loadAuthentication, getUserAccess } = authContext;
	const { enabledToggles, getEnabledToggles } = applicationContext;
	const { resources, getResources } = resourcesContext;

	useEffect(() => {
		const token = localStorage.getItem('token');
		const workspaceIdentifier = localStorage.getItem('workspaceIdentifier');

		if (token === null || workspaceIdentifier === null || token !== authContext.authenticationToken || workspaceIdentifier !== authContext.currentWorkspace?.identifier) {
			loadAuthentication(token, workspaceIdentifier);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Load toggles only once
	useEffect(() => {
		if (authenticationToken !== null && enabledToggles === null) {
			getEnabledToggles();
		}
	}, [authenticationToken, enabledToggles]);

	//Load resources only once
	useEffect(() => {
		if (authenticationToken !== null && currentWorkspace?.identifier !== null  && resources === null) {
			getResources();
		}
	}, [authenticationToken, currentWorkspace, resources]);

	//Load access only once
	useEffect(() => {
		if (authenticationToken !== null && currentWorkspace?.identifier !== null && userAccess === null) {
			getUserAccess();
		}
	}, [authenticationToken, currentWorkspace, userAccess]);

	if (authContext.loading) {
		return <Loading label="Authenticating" fillScreenHeight={true} />
	}

	if (authenticationToken === null) {
		return <Navigate to='/auth/login'/>
	}

	if (currentWorkspace === null) {
		return <Navigate to='/workspaces/choose'/>
	}

	if (resourcesContext.loading || authContext.userAccess === null) {
		return <Loading label="Retrieving workspace information" />
	}

	if (rest.permissions) {
		const hasAccess = CheckAccess(rest.permissions);
		if (!hasAccess) {
			alertContext.setAlert('Unauthorized Access', 'You do not have the necessary permissions to access this page. Please contact your system administrator for further assistance.', 'danger');
			return <Navigate to='/dashboard'/>
		}
	}

	if (currentWorkspace.billingStatus !== 'active' && currentWorkspace.billingStatus !== 'internal') {
		if (rest.path !== '/billing/select') {
			return <Navigate to='/billing/select'/>
		}
	}

	return React.cloneElement(children, { ...rest });
}

export default LoggedInAndWorkspaceSelectedRoute