import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles';
import Popper from 'popper.js'
import App from './App'
import * as serviceWorker from './serviceWorker'
import theme from './theme';
import darkTheme from './darkTheme';

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

const isDarkMode = false;

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(
	<ThemeProvider theme={isDarkMode ? darkTheme : theme}>
		<App />
	</ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
