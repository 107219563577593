import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { Avatar, useMediaQuery } from '@mui/material';
import { CUSTOMER_RESOURCE_CODE } from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import AuthContext from "../../../context/auth/authContext";
import ResourcesContext from "../../../context/resources/resourcesContext";
import LayoutSidenavLinkFresh from "./LayoutSidenavLinkFresh";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(9)} + 1px)`, // Default collapsed width for larger screens
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(11)} + 1px)`,
	},
	[theme.breakpoints.down('sm')]: {
		width: '0px', // Collapse to 0px on small screens
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),
	}),
);

//const { CUSTOMER_RESOURCE_CODE } = require('../../constants');

export default function LayoutSidenavFresh({ open, setOpen, ...props }) {
	const authContext = useContext(AuthContext);
	const resourcesContext = useContext(ResourcesContext);

	const { window } = props;
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	// const [open, setOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setOpen(!open);
	};

	const { workspaces, currentWorkspace, loadAuthentication } = authContext;
	const { resources, getResources } = resourcesContext;

	const listItemButtonSx = {
		minHeight: 56,
		justifyContent: open ? 'initial' : 'center',
		px: 0,
		py: 1.8,
		m: 2,
		borderRadius: 2.5,
		'&:hover': {
			backgroundColor: theme.palette.gray.gray05,
			'& .MuiListItemIcon-root': {
				color: theme.palette.primary.main,
			},
			'& .MuiTypography-root': {
				color: theme.palette.primary.main,
			},
		},
	};

	const listItemIconSx = {
		minWidth: 0,
		mr: open ? 0 : 'auto',
		px: 2,
		display: 'flex',
		justifyContent: 'center',
		color: theme.palette.gray.gray8,
		fontSize: '1.8rem'
	};

	const listItemTextSx = {
		opacity: open ? 1 : 0,
		fontSize: '18px', //'1.0rem',
		fontWeight: '500',
		color: theme.palette.gray.gray8,
		fontFamily: '"Inter", sans-serif',
	}

	return (
		<>
			<Drawer
				variant={isSmallScreen ? 'permanent' : 'permanent'}
				open={open}
				onClose={isSmallScreen ? handleDrawerToggle : undefined}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					zIndex: (theme) => theme.zIndex.drawer,
					'& .MuiDrawer-paper': {
						border: 'none',
						boxShadow: 'none',
					},
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<List sx={{ flexGrow: 1 }}>
						<ListItem disablePadding sx={{ display: { xs: 'block', sm: 'none' }}}>
							<ListItemButton sx={listItemButtonSx}>
								<ListItemIcon sx={listItemIconSx}>
									<FontAwesomeIcon icon="times" fixedWidth onClick={handleDrawerToggle} />
								</ListItemIcon>
							</ListItemButton>
						</ListItem>

						<LayoutSidenavLinkFresh icon="gauge" text="Dashboard" open={open} to="/dashboard" />
						<LayoutSidenavLinkFresh icon="calendar" text="Calendar" open={open} to="/calendar" />

						{resources?.filter(resource => (resource.resourceCode === CUSTOMER_RESOURCE_CODE)).map(resource => (
							<LayoutSidenavLinkFresh key={resource.identifier} icon={resource.icon} text={resource.pluralName} open={open} to="/customers" />
						))}
						{resources?.filter(resource => (resource.type === 'custom')).map(resource => (
							<LayoutSidenavLinkFresh key={resource.identifier} icon={resource.icon} text={resource.pluralName} open={open} to={`/resources/${resource.resourceCode}`} />
						))}
						<LayoutSidenavLinkFresh icon="plus" text="Add New" open={open} backgroundColor={theme.palette.gray.gray05} />
					</List>
					<Divider sx={{ boxShadow: '0 0 30px #000' }} />
					<Box
						sx={{
							position: 'sticky',
							bottom: 0,
							width: '100%',
							bgcolor: theme.palette.background.paper,
							// p: 2,
							boxShadow: `0 -30px 20px ${theme.palette.background.paper}`
						}}
					>
						<ListItem disablePadding sx={{my:1}}>
							<ListItemButton sx={{
								minHeight: 56,
								justifyContent: open ? 'initial' : 'center',
								px: 1.5,
								borderRadius: 2,
								'&:hover': {
									backgroundColor: theme.palette.action.hover,
								},
							}}>
								<ListItemIcon sx={listItemIconSx}>
									<Avatar src='https://app.botscheduling.com/img/avatars/1.png' sx={{p:0, m:0}} />
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding sx={{ display: { xs: 'none', sm: 'block' }}}>
							<ListItemButton
								sx={{
									minHeight: 56,
									justifyContent: open ? 'initial' : 'left',
									px: 1.5,
									borderRadius: 2,
									'&:hover': {
										backgroundColor: theme.palette.action.hover,
									},
								}}
							>
								<ListItemIcon sx={listItemIconSx} onClick={handleDrawerToggle}>
									<FontAwesomeIcon icon="bars" fixedWidth />
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
					</Box>
				</Box>
			</Drawer>

		</>
	);
}
