import React, { lazy } from 'react'

// Layouts
// import Layout1 from './shared/layouts/Layout1'
// import Layout1Flex from './shared/layouts/Layout1Flex'
import Layout2 from './shared/layouts/Layout2'
import LayoutFresh from './shared/layouts/LayoutFresh'
// import LayoutHorizontalSidenav from './shared/layouts/LayoutHorizontalSidenav'
// import LayoutWithoutSidenav from './shared/layouts/LayoutWithoutSidenav'
// import LayoutWithoutNavbar from './shared/layouts/LayoutWithoutNavbar'
// import LayoutWithoutNavbarFlex from './shared/layouts/LayoutWithoutNavbarFlex'
import LayoutBlank from './shared/layouts/LayoutBlank'
import LayoutBlankFresh from "./shared/layouts/LayoutBlankFresh";

// ---
// Default application layout

export const DefaultLayout = Layout2

// ---
// Document title template

export const titleTemplate = '%s - BotScheduling'

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/dashboard'
export const routes = [
	{
		path: '/create',
		component: lazy(() => import('./pages/Create')),
		layout: LayoutBlank,
		auth: 'notLoggedIn'
	}, {
		//   path: '/login',
		//   component: lazy(() => import('./pages/auth/OAuthLogin')),
		//   layout: LayoutBlank,
		//   auth: 'oauth'
		// }, {
		path: '/auth/login',
		component: lazy(() => import('./pages/auth/Login')),
		layout: LayoutBlankFresh,
		auth: 'notLoggedIn'
	}, {
		path: '/auth/logout',
		component: lazy(() => import('./pages/auth/Logout')),
		layout: LayoutBlank,
		auth: 'public'
	}, {
		path: '/auth/reset-password',
		component: lazy(() => import('./pages/auth/ResetPassword')),
		layout: LayoutBlank,
		auth: 'public'
	}, {
		path: '/auth/change-password/:token',
		component: lazy(() => import('./pages/auth/ChangePassword')),
		layout: LayoutBlank,
		auth: 'public'
	}, {
		path: '/workspaces/choose',
		component: lazy(() => import('./pages/workspaces/Choose')),
		layout: LayoutBlank,
		auth: 'loggedIn'
	}, {
		path: '/workspaces/create',
		component: lazy(() => import('./pages/workspaces/Create')),
		layout: LayoutBlank,
		auth: 'loggedIn'
	}, {
		path: '/workspaces/create/payments',
		component: lazy(() => import('./pages/workspaces/AddPlan')),
		layout: LayoutBlank,
		auth: 'loggedIn'
	}, {
		path: '/action/invite/:token',
		component: lazy(() => import('./pages/action/InviteProcess')),
		layout: LayoutBlank,
		auth: 'public'
	}, {
		path: '/action/verify/:token',
		component: lazy(() => import('./pages/action/VerifyProcess')),
		layout: LayoutBlank,
		auth: 'public'
	}, {
		path: '/action/resetPassword/:token',
		component: lazy(() => import('./pages/auth/ChangePassword')),
		layout: LayoutBlank,
		auth: 'public'
	},

	{
		path: '/locations',
		component: lazy(() => import('./pages/locations/Locations'))
	}, {
		path: '/locations/:locationIdentifier/settings',
		component: lazy(() => import('./pages/locations/Settings'))
	}, {
		path: '/locations/:locationIdentifier',
		component: lazy(() => import('./pages/locations/Location'))
	}, {
		path: '/schedules',
		component: lazy(() => import('./pages/schedules/Schedules-old'))
	}, {
		path: '/schedules/settings',
		component: lazy(() => import('./pages/schedules/Settings'))
	}, {
		//   path: '/schedules/:scheduleIdentifier/preview',
		//   component: lazy(() => import('./pages/schedules/SchedulePreview'))
		// }, {
		path: '/schedules/:scheduleIdentifier',
		component: lazy(() => import('./pages/schedules/Schedule'))
	}, {
		path: '/permissions',
		component: lazy(() => import('./pages/workspaces/Permissions')),
		permissions: 'permissions.roles.list'
	}, {
		path: '/manage/developer',
		component: lazy(() => import('./pages/manage/Developer'))
	}, {
		path: '/permissions/:roleIdentifier',
		component: lazy(() => import('./pages/workspaces/Permission')),
		permissions: 'permissions.roles.list'
	}, {
		path: '/groups',
		component: lazy(() => import('./pages/groups/Groups'))
	}, {
		path: '/groups/settings',
		component: lazy(() => import('./pages/groups/Settings'))
	}, {
		path: '/groups/:groupIdentifier',
		component: lazy(() => import('./pages/groups/Group'))
	}, {
		//   path: '/settings',
		//   component: lazy(() => import('./pages/workspaces/Settings')),
		//   permissions: 'settings.management.list|workspace.management.update|workspace.management.cancel'
		// }, {
		path: '/users',
		component: lazy(() => import('./pages/workspaces/Users')),
		permissions: 'users.details.list'
	}, {
		path: '/profile',
		component: lazy(() => import('./pages/profile/Profile'))
	}, {
		path: '/profile/settings',
		component: lazy(() => import('./pages/profile/UserSettings'))
	}, {
		path: '/apps',
		component: lazy(() => import('./pages/workspaces/Apps'))
	}, {
		path: '/billing',
		component: lazy(() => import('./packages/billing/index'))
	}, {
		path: '/billing/select',
		component: lazy(() => import('./packages/billing/index')),
		layout: LayoutBlank
	}, {
		path: '/tickets',
		component: lazy(() => import('./pages/profile/Tickets'))
	}, {
		path: '/tickets/:ticketNumber',
		component: lazy(() => import('./pages/profile/Ticket'))
	},

	{
		path: '/settings/:settingName',
		component: lazy(() => import('./pages/settings/Settings'))
		// permissions: 'settings.management.list|workspace.management.update|workspace.management.cancel'
	},

	{
		//   path: '/bot',
		//   component: lazy(() => import('./pages/bot/Bot'))
		// }, {
		path: '/dashboard/*',
		component: lazy(() => import('./packages/dashboard/index'))
	}, {
		path: '/calendar',
		component: lazy(() => import('./pages/appointments/AppointmentCalendar'))
	}, {
		path: '/activities',
		component: lazy(() => import('./pages/activity/Activities')),
		permissions: 'activity.monitor.list'
	}, {
		path: '/appointments',
		component: lazy(() => import('./pages/appointments/Appointments'))
	}, {
		path: '/appointments/calendar',
		component: lazy(() => import('./pages/appointments/AppointmentCalendar'))
	}, {
		path: '/appointments/create',
		component: lazy(() => import('./pages/appointments/AppointmentCreate'))
	}, {
		path: '/appointments/:appointmentIdentifier',
		component: lazy(() => import('./pages/appointments/Appointment'))
	}, {
		path: '/customers',
		component: lazy(() => import('./pages/customers/Customers'))
	}, {
		path: '/customers/settings',
		component: lazy(() => import('./pages/customers/Settings'))
	}, {
		path: '/customers/:customerIdentifier/appointments',
		component: lazy(() => import('./pages/customers/Appointments'))
	}, {
		path: '/customers/:customerIdentifier',
		component: lazy(() => import('./pages/customers/Customer'))
	}, {
		//   path: '/resource-management',
		//   component: lazy(() => import('./pages/custom/CustomObjects')),
		//   permissions: 'resources.management.list'
		// }, {
		path: '/resource-management/:customObjectIdentifier',
		component: lazy(() => import('./pages/manage/Objects')),
		permissions: 'resources.management.manage'
	}, {
		path: '/services',
		component: lazy(() => import('./pages/services/Services'))
	}, {
		path: '/services/settings',
		component: lazy(() => import('./pages/services/Settings'))
	}, {
		path: '/services/:serviceIdentifier',
		component: lazy(() => import('./pages/services/Service'))
	}, {
		path: '/services/:serviceIdentifier/resources/:resourceCode',
		component: lazy(() => import('./pages/services/Resources'))
	}, {
		path: '/statuses',
		component: lazy(() => import('./pages/statuses/Statuses')),
		permissions: 'status.management.list'
	}, {
		path: '/statuses/:statusIdentifier',
		component: lazy(() => import('./pages/statuses/Status')),
		permissions: 'status.management.list'
	}, {
		path: '/files',
		component: lazy(() => import('./pages/files/Files'))
	}, {
		path: '/resources/:resourceCode',
		component: lazy(() => import('./pages/resources/Resources'))
	}, {
		path: '/resources/:resourceCode/:dataIdentifier',
		component: lazy(() => import('./pages/resources/Resource'))
	}, {
		path: '/updates',
		component: lazy(() => import('./pages/updates/Updates'))
	}
]