import React, { Suspense } from 'react'
import { BrowserRouter as AppRouter, Navigate, Route, Routes } from 'react-router-dom'

import AppError from './AppError'
import ServiceError from './ServiceError'
import NotFound from './NotFound'
import Loading from "../components/Loading";

import LoggedInAndWorkspaceSelectedRoute from '../routing/LoggedInAndWorkspaceSelectedRoute';
import LoggedInRoute from "../routing/LoggedInRoute";
import NotLoggedInRoute from "../routing/NotLoggedInRoute";
import PublicRoute from "../routing/PublicRoute";

// Routes
import { DefaultLayout, titleTemplate, defaultRoute, routes } from '../routes'

const Router = () => {
	const isRTL = false

	// Set default layout
	routes.map(route => {
		route.layout = route.layout || DefaultLayout
		route.exact = typeof route.exact === 'undefined' ? true : route.exact
		return route
	})

	//TODO: This is replaced by application context
	const setTitle = (title) => {
		document.title = titleTemplate.replace('%s', title)
	};

	const scrollTop = (to, duration, element = document.scrollingElement || document.documentElement) => {
		if (element.scrollTop === to) return

		const start = element.scrollTop
		const change = to - start
		const startDate = +new Date()

		if (!duration) {
			element.scrollTop = to
			return
		}

		// t = current time; b = start value; c = change in value; d = duration
		const easeInOutQuad = (t, b, c, d) => {
			t /= d / 2
			if (t < 1) return c / 2 * t * t + b
			t--
			return -c / 2 * (t * (t - 2) - 1) + b
		}

		const animateScroll = () => {
			const currentDate = +new Date()
			const currentTime = currentDate - startDate
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll)
			} else {
				element.scrollTop = to
			}
		}

		animateScroll()
	};

	const routeRender = (route, props) => {
		// On small screens collapse sidenav
		if (window.layoutHelpers && window.layoutHelpers.isSmallScreen()) {
			window.layoutHelpers.setCollapsed(true, false)
		}

		// Scroll page to top on route render
		scrollTop(0, 0)

		const { layout: Layout, auth, component: Component } = route;
		const AuthWrapper = authWrappers[auth] || authWrappers['loggedInAndWorkspaceSelected'];

		return (
			<AuthWrapper permissions={route.permissions}>
				<Layout>
					<Component {...props} setTitle={setTitle} scrollTop={scrollTop} isRTL={isRTL} />
				</Layout>
			</AuthWrapper>
		);
	};

	const authWrappers = {
		public: PublicRoute,
		notLoggedIn: NotLoggedInRoute,
		loggedIn: LoggedInRoute,
		loggedInAndWorkspaceSelected: LoggedInAndWorkspaceSelectedRoute
	};

	return (
		<AppRouter basename={process.env.REACT_APP_BASENAME}>
			<Suspense fallback={<Loading ready={false} />}>
				<Routes>
					{routes.map((route, index) => (
						<Route
							element={routeRender(route, { setTitle, scrollTop, isRTL })}
							key={index}
							path={route.path}
						/>
					))}

					{defaultRoute !== '/' &&
						<Route path="/" element={<Navigate to={defaultRoute} replace />} />
					}

					{/* NotFound page */}
					<Route path="/app/error" element={<AppError/> } />
					<Route path="/app/service" element={<ServiceError/> } />
					<Route path="*" element={<NotFound/> } />
				</Routes>
			</Suspense>
		</AppRouter>
	);
};

export default Router