import { createTheme } from '@mui/material/styles';

const gray05 = '#f6f6f4';

const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#3ba4c1',
		},
		secondary: {
			main: '#dc004e',
		},
		gray: {
			gray05: gray05,
			gray1: '#f5f4f1',
			gray2: '#e4e3e0',
			gray8: '#48413f',
		},
		success: {
			green05: '#5BA691',
			main: '#427869',
		},
		background: {
			main: gray05, // Use this as the main background color
			paper: '#ffffff',   // Use this for surfaces like Paper components
		},
		text: {
			primary: '#4E5155', // Lighter text for better readability
			secondary: '#bdbdbd', // Subtle secondary text color
		},
	},
});

export default theme;