import React, { useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Grid2 as Grid, Typography, Link, Badge } from "@mui/material";
import { styled, alpha, useTheme } from '@mui/material/styles';
import useChat from "../../hooks/useChat";

import ApplicationContext from "../../context/application/applicationContext";

const LayoutFooterFresh = (props) => {
	const year = new Date().getFullYear();
	useChat();

	const applicationContext = useContext(ApplicationContext);

	useEffect(() => {
		if (props.currentWorkspace) {
			applicationContext.getDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.currentWorkspace]);

	const { details } = applicationContext;

	const theme = useTheme();

	return (
		<Box
			component="footer"
			sx={{
				position: "static",
				width: "100%",
				backgroundColor: theme.palette.background.paper,
				padding: 3,
			}}
		>
			<Grid container spacing={2}>
				<Grid
					size={{ xs: 12, md: 6 }}
					textAlign={{ xs: "center", md: "left" }}
				>
					<Link
						component={RouterLink}
						to="/updates"
						sx={{
							marginRight: 3,
							color: "inherit",
							textDecoration: "none"
						}}
					>
						Updates
					</Link>
					<Link
						href="https://botscheduling.statuspage.io"
						target="_blank"
						rel="noreferrer"
						sx={{
							marginRight: 3,
							color: "inherit",
							textDecoration: "none"
						}}
					>
						<Badge
							colori="black"
							color={
								!applicationContext.loading && details?.status?.length > 0
									? "error"
									: "success"
							}
							variant="dot"
							sx={{ marginRight: "0.5rem" }}
						/>
						Status
					</Link>
					<Link
						href="https://botscheduling.com/docs"
						target="_blank"
						rel="noreferrer"
						sx={{ margin: "0 1rem 0 0", color: "inherit", textDecoration: "none" }}
					>
						Docs
					</Link>
					<Link
						href="https://botscheduling.com/terms"
						target="_blank"
						rel="noreferrer"
						sx={{
							marginRight: 3,
							color: "inherit",
							textDecoration: "none",
						}}
					>
						Terms
					</Link>
					<Link
						href="https://botscheduling.com/privacy"
						target="_blank"
						rel="noreferrer"
						sx={{
							color: "inherit",
							textDecoration: "none",
						}}
					>
						Privacy
					</Link>
				</Grid>
				<Grid
					item
					size={{ xs: 12, md: 6 }}
					sx={{
						display: "flex",
						justifyContent: { xs: "center", md: "right" },
						alignItems: "center",
					}}
				>
					<Typography align="right">
						&copy; {year} BotScheduling. All rights reserved.
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default LayoutFooterFresh;
