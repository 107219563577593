import React, {useEffect } from 'react';
import { Button as BootstrapButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = ({ icon = null, variant = 'primary', loading = false, disabled = false, onClick = () => {}, keyDown = null, children, ...props }) => {
	useEffect(() => {
		if (keyDown) {
			document.addEventListener('keydown', keyDown);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BootstrapButton variant={variant ?? 'primary'} className={`${props.textColor ?? ''} text-uppercase`} disabled={(loading !== undefined && loading !== false) || disabled} onClick={onClick} {...props}>
			{loading === undefined || loading === false ?
				<>{icon && <FontAwesomeIcon icon={icon} /> } {children}</>
			:
				<><FontAwesomeIcon icon="circle-notch" className="text-white" spin={true} /> {children}</>
			}
		</BootstrapButton>
	);
}

export default Button