import React, { Fragment, useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Button } from "../../components/layout/BotSchedulingUI";
import { Navbar, Nav, FormControl, Dropdown } from 'react-bootstrap';
import { Avatar, Skeleton } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Access from "../../components/Access";
import md5 from 'md5';
import CreateCustomerModal from "../../pages/customers/modals/CreateCustomerModal";

import PropTypes from 'prop-types'
import layoutHelpers from './helpers'

import ChatModal from "./components/ChatModal";
import LayoutNavbarAppointmentModal from "./components/LayoutNavbarAppointmentModal";
import {
    CUSTOMER_RESOURCE_CODE,
    LOCATION_RESOURCE_CODE,
    SERVICE_RESOURCE_CODE
} from "../../constants";

import ResourcesContext from "../../context/resources/resourcesContext";
import ChatContext from "../../context/chat/chatContext";
import AppointmentCreateContext from "../../context/appointmentCreate/appointmentCreateContext";

const LayoutNavbar = (props) => {
  const resourcesContext = useContext(ResourcesContext);
  const chatContext = useContext(ChatContext);
  const appointmentCreateContext = useContext(AppointmentCreateContext);

  const [displayCreateCustomerModal, setDisplayCreateCustomerModal] = useState(false);

  const isRTL = false; //const isRTL = document.documentElement.getAttribute('dir') === 'rtl'

  const toggleSidenav = (event) => {
    event.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  //Chat
  const [displayChatModal, setDisplayChatModal] = useState(false);

  const [chatValue, setChatValue] = useState('');

  const handleClick = (event) => {
    setDisplayChatModal(true);
  };

  const handleChange = (event) => {
    setChatValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && chatValue !== '') {
      setDisplayChatModal(true);
      chatContext.sendChat(chatValue);
      setChatValue('');
    }
  };

  const handleCloseCreateCustomerModal = () => {
    setDisplayCreateCustomerModal(false);
  };

  const handleCloseChatModal = () => {
    setDisplayChatModal(false);
  };

  const handleClearConversations = () => {
    chatContext.resetChat();
  };

  const handleCreateAppointmentModal = () => {
    appointmentCreateContext.showModal(true);
  }

  return (
    <div>
      <Navbar bg="navbar-theme" className="layout-navbar align-items-lg-center container-p-x p-1">
        <Navbar.Brand onClick={toggleSidenav} className="app-brand demo d-lg-none py-0 mr-3">
          {props.currentWorkspace
              ?
              <Fragment>
                {/*<i className="btn btn-primary text-md-center rounded-pill p-0 pl-2 pr-2 text-big"><strong>{props.currentWorkspace.name.slice(0, 1)}</strong></i>*/}
                {/*<span className="app-brand-text demo font-weight-normal ml-2">{props.currentWorkspace.name}</span>*/}
                <Avatar
                    className="bg-primary"
                    id="basic-button"
                    aria-haspopup="true"
                    style={{"cursor": "pointer"}}
                >
                  {props.currentWorkspace.name.slice(0, 1)}
                </Avatar>
                <span className="app-brand-text demo font-weight-normal ml-2 d-none d-md-inline">{props.currentWorkspace.name}</span>
              </Fragment>
              :
              <Fragment>
                <Skeleton variant="circular" width={30} height={30} sx={{bgcolor: 'grey.600'}} className="mr-2" />
                <Skeleton variant="rounded" width={100} sx={{bgcolor: 'grey.600'}} className="d-none d-md-inline" />
              </Fragment>
          }
        </Navbar.Brand>

        {props.sidenavToggle && (
            <Nav className="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto">
              <Nav.Item as="a" className="nav-link px-0 mr-lg-4" href="#toggle" onClick={toggleSidenav}>
                {/*<i className="ion ion-md-menu text-large align-middle"></i>*/}
                <FontAwesomeIcon icon="bars" size="lg" />
              </Nav.Item>
            </Nav>
        )}

        <Navbar.Toggle />

        <Navbar.Collapse>
          {/* Divider */}
          {/*<hr className="d-lg-none w-100 my-2" />*/}

          <Nav className="align-items-center d-none d-lg-block">
            {/* Search */}
            <label className="nav-item navbar-text navbar-search-box p-0 active">
              <FontAwesomeIcon className="navbar-icon align-middle cursor-pointer" icon="robot" onClick={handleClick} />
              <span className="navbar-search-input pl-2">
              <FormControl className="navbar-text mx-2"
                placeholder="Ask a bot"
                style={{width: '200px'}}
                value={chatValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </span>
            </label>
          </Nav>

          <Nav className="align-items-center ml-auto">
            <Dropdown as={Nav.Item} className="d-block d-lg-none demo-navbar-user">
              <Dropdown.Toggle as={Nav.Link} className="hide-arrow">
                <FontAwesomeIcon icon="robot" onClick={handleClick} />
              </Dropdown.Toggle>
            </Dropdown>

            <Dropdown as={Nav.Item} className="d-none d-sm-block demo-navbar-user" alignRight={!isRTL}>
              <Dropdown.Toggle as={Nav.Link} className="hide-arrow">
                <Button variant="primary" size="md" className="mr-0 text-uppercase">
                  <FontAwesomeIcon icon={'add'} className="text-white" /><span className="d-none d-md-inline"> Create</span>
                </Button>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleCreateAppointmentModal()}>
                  <FontAwesomeIcon icon={'calendar-day'} className="text-light mr-2" fixedWidth={true} />Appointment
                </Dropdown.Item>
                {resourcesContext.resources?.filter(resource => (resource.resourceCode === CUSTOMER_RESOURCE_CODE)).map(resource => (
                  <Dropdown.Item onClick={() => { setDisplayCreateCustomerModal(true)}} key={resource.identifier}>
                    <FontAwesomeIcon icon={resource.icon} className="text-light mr-2" fixedWidth={true} />{resource.singularName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {resourcesContext.resources !== null &&
              <CreateCustomerModal displayModal={displayCreateCustomerModal} handleCloseModal={handleCloseCreateCustomerModal} {...props} />
            }

            {/*<Dropdown as={Nav.Item} className="demo-navbar-notifications mr-lg-3" alignRight={!isRTL}>*/}
            {/*  <Dropdown.Toggle as={Nav.Link} className="hide-arrow">*/}
            {/*    <i className="ion ion-md-notifications-outline navbar-icon align-middle"></i>*/}
            {/*    <Badge variant="primary badge-dot indicator"></Badge>*/}
            {/*    <span className="d-lg-none align-middle">&nbsp; Notifications</span>*/}
            {/*  </Dropdown.Toggle>*/}

            {/*  <Dropdown.Menu>*/}
            {/*    <div className="bg-primary text-center text-white font-weight-bold p-3">*/}
            {/*      4 New Notifications*/}
            {/*    </div>*/}
            {/*    <ListGroup variant="flush">*/}
            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <div className="ui-icon ui-icon-sm flex-shrink-0 ion ion-md-home bg-secondary border-0 text-white"></div>*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body">Login from 192.168.1.1</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            Aliquam ex eros, imperdiet vulputate hendrerit et.*/}
            {/*          </div>*/}
            {/*          <div className="text-light small mt-1">12h ago</div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}

            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <div className="ui-icon ui-icon-sm flex-shrink-0 ion ion-md-person-add bg-info border-0 text-white"></div>*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body">You have <strong>4</strong> new followers</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            Phasellus nunc nisl, posuere cursus pretium nec, dictum vehicula tellus.*/}
            {/*          </div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}

            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <div className="ui-icon ui-icon-sm flex-shrink-0 ion ion-md-power bg-danger border-0 text-white"></div>*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body">Server restarted</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            19h ago*/}
            {/*          </div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}

            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <div className="ui-icon ui-icon-sm flex-shrink-0 ion ion-md-warning bg-warning border-0 text-body"></div>*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body">99% server load</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            Etiam nec fringilla magna. Donec mi metus.*/}
            {/*          </div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            20h ago*/}
            {/*          </div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}
            {/*    </ListGroup>*/}

            {/*    <a href="#link" className="d-block text-center text-light small p-2 my-1" onClick={e => e.preventDefault()}>Show all notifications</a>*/}
            {/*  </Dropdown.Menu>*/}
            {/*</Dropdown>*/}

            {/*<Dropdown as={Nav.Item} className="demo-navbar-messages mr-lg-3" alignRight={!isRTL}>*/}
            {/*  <Dropdown.Toggle as={Nav.Link} className="hide-arrow">*/}
            {/*    <i className="ion ion-ios-mail navbar-icon align-middle"></i>*/}
            {/*    <Badge variant="primary badge-dot indicator"></Badge>*/}
            {/*    <span className="d-lg-none align-middle">&nbsp; Messages</span>*/}
            {/*  </Dropdown.Toggle>*/}

            {/*  <Dropdown.Menu>*/}
            {/*    <div className="bg-primary text-center text-white font-weight-bold p-3">*/}
            {/*      4 New Messages*/}
            {/*    </div>*/}
            {/*    <ListGroup variant="flush">*/}
            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <img src={`${process.env.PUBLIC_URL}/img/avatars/6-small.png`} className="d-block flex-shrink-0 ui-w-40 rounded-circle" alt="User" />*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            Mae Gibson &nbsp;·&nbsp; 58m ago*/}
            {/*          </div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}

            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <img src={`${process.env.PUBLIC_URL}/img/avatars/4-small.png`} className="d-block flex-shrink-0 ui-w-40 rounded-circle" alt="User" />*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body line-height-condenced">Mea et legere fuisset, ius amet purto luptatum te.</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            Kenneth Frazier &nbsp;·&nbsp; 1h ago*/}
            {/*          </div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}

            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <img src={`${process.env.PUBLIC_URL}/img/avatars/5-small.png`} className="d-block flex-shrink-0 ui-w-40 rounded-circle" alt="User" />*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            Nelle Maxwell &nbsp;·&nbsp; 2h ago*/}
            {/*          </div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}

            {/*      <ListGroup.Item as={Media} action className="d-flex align-items-center">*/}
            {/*        <img src={`${process.env.PUBLIC_URL}/img/avatars/11-small.png`} className="d-block flex-shrink-0 ui-w-40 rounded-circle" alt="User" />*/}
            {/*        <Media.Body className="line-height-condenced ml-3">*/}
            {/*          <div className="text-body line-height-condenced">Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.</div>*/}
            {/*          <div className="text-light small mt-1">*/}
            {/*            Belle Ross &nbsp;·&nbsp; 5h ago*/}
            {/*          </div>*/}
            {/*        </Media.Body>*/}
            {/*      </ListGroup.Item>*/}
            {/*    </ListGroup>*/}

            {/*    <a href="#link" className="d-block text-center text-light small p-2 my-1" onClick={e => e.preventDefault()}>Show all messages</a>*/}
            {/*  </Dropdown.Menu>*/}
            {/*</Dropdown>*/}

            <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!isRTL}>
              <Dropdown.Toggle as={Nav.Link} className="hide-arrow">
                <FontAwesomeIcon icon={'grip-vertical'} className="navbar-icon align-middle" />
                {/*<span className="d-lg-none align-middle">&nbsp; Settings</span>*/}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/*{props.currentWorkspace &&*/}
                {/*    <Dropdown.Header>{props.currentWorkspace.name.toUpperCase()}</Dropdown.Header>*/}
                {/*}*/}
                {resourcesContext.resources?.filter(resource => (resource.resourceCode === LOCATION_RESOURCE_CODE)).map(resource => (
                  <Dropdown.Item as={Link} to="/locations" key={resource.identifier}>
                    <FontAwesomeIcon icon={resource.icon} className="text-light mr-2" fixedWidth={true} />
                    {resource.pluralName}
                  </Dropdown.Item>
                ))}
                {resourcesContext.resources?.filter(resource => (resource.resourceCode === SERVICE_RESOURCE_CODE)).map(resource => (
                    <Dropdown.Item as={Link} to="/services" key={resource.identifier}>
                      <FontAwesomeIcon icon={resource.icon} className="text-light mr-2" fixedWidth={true} />
                      {resource.pluralName}
                    </Dropdown.Item>
                ))}
                {/*{resourcesContext.resources?.filter(resource => (resource.resourceCode === 'sch')).map(resource => (*/}
                {/*  <Dropdown.Item as={Link} to="/schedules"><FontAwesomeIcon icon={resource.icon} className="text-light mr-2" fixedWidth={true} />{resource.pluralName}</Dropdown.Item>*/}
                {/*))}*/}
                <Access permission="files.management.read">
                  <Dropdown.Item as={Link} to="/files">
                    <FontAwesomeIcon icon={'file'} className="text-light mr-2" fixedWidth={true} />
                    Files
                  </Dropdown.Item>
                </Access>
              </Dropdown.Menu>
            </Dropdown>

            <Nav.Link as={NavLink} to="/settings/workspace">
              <FontAwesomeIcon icon="gear" size="xl" fixedWidth={true} />
            </Nav.Link>

            {/* Divider */}
            <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-2 ml-2">|</div>

            <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!isRTL}>
              <Dropdown.Toggle as={Nav.Link}>
                <span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                {props.loggedInUser && props.loggedInUser.email
                    ?
                    <Fragment>
                      <img
                          src={`https://www.gravatar.com/avatar/${md5(props.loggedInUser.email)}?d=https://app.botscheduling.com/img/avatars/1.png`}
                          className="d-block ui-w-30 rounded-circle" alt="User"/>
                      <span className="d-none d-lg-block px-1 mr-lg-2 ml-2 ml-lg-0">{props.loggedInUser.name}</span>
                    </Fragment>
                    :
                    <Fragment>
                      <Skeleton variant="circular" width={30} height={30} sx={{bgcolor: 'grey.600'}} />
                      <Skeleton variant="rounded" width={100} sx={{bgcolor: 'grey.600'}} className="d-none d-lg-block px-1 mr-lg-2 ml-2 ml-lg-0" />
                    </Fragment>
                }
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/profile"><FontAwesomeIcon icon={'address-card'} className="text-light" fixedWidth={true} /> &nbsp; My profile</Dropdown.Item>
                <Dropdown.Item as={Link} to="/profile/settings"><FontAwesomeIcon icon={'user-gear'} className="text-light" fixedWidth={true} /> &nbsp; Settings</Dropdown.Item>
                <Dropdown.Item as={Link} to="/tickets"><FontAwesomeIcon icon={'comments'} className="text-light" fixedWidth={true} /> &nbsp; Tickets</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/auth/logout"><FontAwesomeIcon icon={'arrow-right-from-bracket'} className="text-light" fixedWidth={true} /> &nbsp; Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ChatModal displayModal={displayChatModal} handleCloseModal={handleCloseChatModal} handleClearConversations={handleClearConversations} {...props} />
      <LayoutNavbarAppointmentModal />
    </div>
  )
}

// LayoutNavbar.propTypes = {
//   sidenavToggle: PropTypes.bool
// }
//
// LayoutNavbar.defaultProps = {
//   sidenavToggle: true
// }

export default LayoutNavbar;