import React, { useEffect, useContext, useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import DemoDataBanner from "./components/DemoDataBanner";

import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter'
import layoutHelpers from './helpers'

import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/auth/authContext";
import UsersContext from "../../context/users/usersContext";
import ResourcesContext from "../../context/resources/resourcesContext";

const Layout2 = ({children, ...props}) => {
	//load all the contexts
	const alertContext = useContext(AlertContext);
	const authContext = useContext(AuthContext);
	const usersContext = useContext(UsersContext);
	const resourcesContext = useContext(ResourcesContext);

	const navigate = useNavigate();

	useEffect(() => {
		layoutHelpers.init()
		layoutHelpers.update()
		layoutHelpers.setAutoUpdate(true)

		const menuCollapsed = JSON.parse(localStorage.getItem('menuCollapsed'));
		const isCollapsed = menuCollapsed !== null ? menuCollapsed : true;
		layoutHelpers.setCollapsed(isCollapsed, false);

		return () => {
			layoutHelpers.destroy()
		}
	}, []);

	const closeSidenav = useCallback((e) => {
		e.preventDefault();
		layoutHelpers.setCollapsed(true);
	}, []);

	const { authenticationToken, currentWorkspace } = authContext;
	const { resources } = resourcesContext;
	const { getLoggedInUser } = usersContext;
	// const authenticationToken = authContext.authenticationToken ?? null;
	// const currentWorkspace = authenticationToken?.currentWorkspace ?? null;
	const user = usersContext?.user ?? null;

	useEffect(() => {
		if (user === null) {
			getLoggedInUser();
		}
	}, [user]);

	//reload resources when workspace changes
	useEffect(() => {
		// if (currentWorkspace !== null) {
		// 	resourcesContext.getResources();
		// }
	}, [currentWorkspace]);

	const inputElement = document.getElementById("st_field2ctrl3");
	const inputElement2 = document.getElementById("st_field3ctrl3");

	useEffect(() => {
		if (user !== null && inputElement && inputElement2) {
			inputElement.value = user.name;
			inputElement2.value = user.emailAddress;
		}
	}, [inputElement, inputElement2, user]);

	props = {
		...props,
		currentWorkspace: currentWorkspace,
		authenticationToken: authenticationToken,
		loggedInUser: user,
		authContext: authContext,
		alertContext: alertContext,
		resources: resourcesContext.resources
	};

	const childrenWithProps = React.cloneElement(children, props);

	return (
		<div className="layout-wrapper layout-2">
			<div className="layout-inner">
				<LayoutSidenav {...props} />

				<div className="layout-container">
					<LayoutNavbar {...props} />

					<div className="layout-content">
						{currentWorkspace.hasDemoData &&
							<DemoDataBanner workspaceIdentifier={currentWorkspace.identifier} {...props} />
						}

						<div className="container-fluid flex-grow-1 container-p-y">
							{props.currentWorkspace && props.authenticationToken && props.loggedInUser && resourcesContext.resources !== null ? childrenWithProps : <Loading />}
						</div>

						<div id="stlivechat1"></div>

						<LayoutFooter {...props} />
					</div>
				</div>
			</div>
			<div className="layout-overlay" onClick={closeSidenav}></div>
		</div>
	)
}

export default Layout2