import React from 'react';
import { Box, Container } from '@mui/material';
import { useTheme } from "@mui/material/styles";

const LayoutBlankFresh = ({ children }) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				minHeight: '100vh',
				display: 'flex',
				alignItems: { xs: 'flex-start', sm: 'center' },
				justifyContent: 'center',
				backgroundColor: theme.palette.background.main,
				color: 'white',
				padding: 3,
				// paddingTop: { xs: 3, sm: 0 },
			}}
		>
			<Container
				maxWidth="sm"
				sx={{
					textAlign: 'center',
					padding: 0,
					// backgroundColor: 'rgba(255, 255, 255, 0.9)',
					// borderRadius: 2,
					// boxShadow: 3,
				}}
			>
				{children}
			</Container>
		</Box>
	);
};

export default LayoutBlankFresh;