import { createTheme } from '@mui/material/styles';

// Define reusable colors for the dark theme
const gray05 = '#121212'; // A dark background color
const gray8 = '#e0e0e0'; // Lighter gray for contrast
const green05 = '#81c784'; // A softer green for success states
const primaryMain = '#3ba4c1'; // A lighter shade of blue for better visibility
const secondaryMain = '#f48fb1'; // A softer secondary color

const darkTheme = createTheme({
	palette: {
		mode: 'dark', // Enables dark mode in Material-UI
		primary: {
			main: primaryMain,
		},
		secondary: {
			main: secondaryMain,
		},
		gray: {
			gray05: gray05, // Dark gray background
			gray8: gray8,   // Light gray for text or borders
		},
		success: {
			green05: green05, // Success color
			main: '#388e3c', // Slightly darker green for dark mode
		},
		background: {
			main: gray05, // Main background color for the app
			paper: '#1e1e1e', // Background color for surfaces
		},
		text: {
			primary: gray8, // Lighter text for better readability
			secondary: '#bdbdbd', // Subtle secondary text color
		},
	},
});

export default darkTheme;
