import React, { useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

import AlertContext from '../../context/alert/alertContext';

const Alert = (props) => {
	const alertContext  = useContext(AlertContext);

	const Msg = ({ title, message, type}) => {
		const type2 = type === 'error' ? 'danger' : type;
		const type3 = type === 'info' ? 'primary' : type2;

		return (<>
			<p className={`text-${type3} font-weight-bold p-0 m-0`}>{title}</p>
			<span className="text-body">{message}</span>
		</>);
	};

	const toastTypes = {
		success: toast.success,
		error: toast.error,
		info: toast.info,
	};

	useEffect(() => {
		for (const alert of alertContext.alerts) {
			const toastFunction = toastTypes[alert.type] || toast.info;
			toastFunction(<Msg title={alert.title} message={alert.message} type={alert.type} />, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			})

			alertContext.removeAlert(alert)
		}
	}, [alertContext]);

	return (
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick={false}
			rtl={false}
			pauseOnFocusLoss
			pauseOnHover
		/>
	);
}

export default Alert;