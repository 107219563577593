import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";

const SidenavRouterLink = ({
  className,
  icon,
  linkClass,
  badgeText,
  badgeVariant,
  disabled,
  active,
  loading,
  children,
  ...rest
}) => {
  return (
      <NavLink
          activeclassname="active"
          className={`sidenav-item d-block ${className || ''}` +
              (active ? ' active' : '') +
              (disabled ? ' disabled' : '')}
          {...rest}
      >
        <div className={`my-6 py-6 sidenav-link ${linkClass}`}>
          {loading ? (
              <FontAwesomeIcon
                  icon="circle-notch"
                  size="10x"
                  className="sidenav-icon mr-2"
                  fixedWidth={true}
                  spin
              />
          ) : (
              icon && (
                  <FontAwesomeIcon
                      icon={icon}
                      size="10x"
                      className="sidenav-icon mr-2"
                      fixedWidth={true}
                  />
              )
          )}
          <div className="font-weight-semibold">
            {loading ? (
                <Skeleton
                    variant="rounded"
                    sx={{ bgcolor: 'white' }}
                    width={90}
                />
            ) : (
                children
            )}
          </div>
          {badgeText && (
              <div className="pl-1 ml-auto">
                <div className={`badge badge-${badgeVariant}`}>
                  {badgeText}
                </div>
              </div>
          )}
        </div>
      </NavLink>
  );
};

// SidenavRouterLink.propTypes = {
//   icon: PropTypes.string,
//   linkClass: PropTypes.string,
//   badgeText: PropTypes.string,
//   badgeVariant: PropTypes.string,
//   disabled: PropTypes.bool,
//   active: PropTypes.bool,
//   loading: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
// };
//
// SidenavRouterLink.defaultProps = {
//   icon: '',
//   linkClass: '',
//   badgeText: '',
//   badgeVariant: 'primary',
//   disabled: false,
//   active: false,
//   loading: false,
//   className: '',
//   children: null,
// };

export default SidenavRouterLink;