import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled, alpha, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	width: '100%',
	[theme.breakpoints.up('sm')]: {
		width: 'auto',
	},
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
}));

export default function LayoutNavbarFresh({ open, setOpen, ...props }) {
	const navigate = useNavigate();
	const [scrolled, setScrolled] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	useEffect(() => {
		const handleScroll = () => setScrolled(window.scrollY > 0);
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
	const handleMenuClose = () => setAnchorEl(null);
	const handleDrawerToggle = () => setOpen(!open);

	return (
		<AppBar
			position="sticky"
			sx={{
				backgroundColor: theme.palette.background.paper,
				boxShadow: 'none',
				zIndex: theme.zIndex.drawer - 1,
				borderBottom: scrolled ? '1px solid #ececec' : 'none',
				transition: 'border-bottom 0.3s ease',
			}}
			elevation={0}
		>
			<Toolbar
				sx={{
					minHeight: {
						xs: 70,
						sm: 80,
						md: 100,
					},
				}}
			>
				<Box>
					{isSmallScreen && (
						<IconButton
							size="large"
							sx={{
								color: theme.palette.gray.gray8,
								marginRight: 1,
								// padding: 1,
								// borderRadius: '50%',
								'&:hover': {
									backgroundColor: alpha(theme.palette.primary.main, 0.1), // Adds a subtle hover effect
								},
							}}
							onClick={handleDrawerToggle}
						>
							<FontAwesomeIcon icon="bars" size="xs" fixedWidth />
						</IconButton>
					)}
				</Box>


				<Typography
					variant="h6"
					noWrap
					component="div"
					sx={{ color: 'gray', fontFamily: 'Inter' }}
				>
					{props.currentWorkspace.name}
				</Typography>

				<Box sx={{ flexGrow: 1 }} />

				<Search sx={{ display: { xs: 'none', sm: 'block' }, border: '1px solid #ececec', borderRadius: '20px' }}>
					<SearchIconWrapper>
						<SearchIcon />
					</SearchIconWrapper>
					<StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
				</Search>

				<Box>
					{isSmallScreen && (
						<IconButton size="large" sx={{ color: theme.palette.gray.gray8}} >
							< FontAwesomeIcon icon="magnifying-glass" size="xs" fixedWidth />
						</IconButton>
					)}

					<IconButton
						size="large"
						sx={{
							marginX: .5,
							color: theme.palette.gray.gray8
						}}
						onClick={handleMenuClick}
						aria-controls={openMenu ? 'dropdown-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={openMenu ? 'true' : undefined}
					>
						<FontAwesomeIcon icon="grip-vertical" size="xs" fixedWidth />
					</IconButton>

					<Menu
						id="dropdown-menu"
						anchorEl={anchorEl}
						open={openMenu}
						onClose={handleMenuClose}
						MenuListProps={{ 'aria-labelledby': 'basic-button' }}
					>
						<MenuItem onClick={handleMenuClose}>
							<Link to="/services" style={{ textDecoration: 'none', color: 'inherit' }}>
								<FontAwesomeIcon icon="location-dot" /> Locations
							</Link>
						</MenuItem>
						<MenuItem onClick={handleMenuClose}>
							<Link to="/locations" style={{ textDecoration: 'none', color: 'inherit' }}>
								<FontAwesomeIcon icon="bell-concierge" /> Services
							</Link>
						</MenuItem>
					</Menu>

					<Link to="/settings/workspace" style={{ textDecoration: 'none', color: 'inherit' }}>
						<IconButton size="large" sx={{ color: theme.palette.gray.gray8 }}>
							<FontAwesomeIcon icon="gear" size="xs" fixedWidth />
						</IconButton>
					</Link>
				</Box>
			</Toolbar>
		</AppBar>
	);
}